/**
 * Main entrypoint of the application.
 * Here is initialized the ControllerDispatcher and loaded the main resources shared by all pages
 */

import 'intersection-observer';
import './modules/utils/EelementRemovePolyfill';
import './modules/utils/NodeListForEachPolyfill';
import './modules/utils/RemoveAppendPolyfill';
import 'core-js/fn/set';
import 'core-js/fn/promise';
import 'core-js/fn/symbol';
import 'core-js/fn/object';
import 'core-js/fn/array';

import 'what-input'; // needed for accessibility
import '../fonts/**';
import '../stylesheets/main.scss';
// import '../stylesheets/devmode.scss';

import './modules/configurations/public-path';
import( /* webpackChunkName: `formbouncerjs` */ 'formbouncerjs').then(module => {
    $(document).ready(()=>{
        const Bouncer = module.default;
        document.newsletterBouncer = new Bouncer('form.newsletter-form', {
            disableSubmit: true, // If true, native form submission is suppressed even when form validates
            emitEvents: true, // If true, emits custom events
            messages: {
                missingValue: {
                    default: $('[data-bouncer-message-missing-value]').attr('data-bouncer-message-missing-value')
                }
            }
        });
    });
}).catch(console.error);


// Load the controller dinamically based on the data attribute of the body
import ControllerDispatcher from './modules/utils/ControllerDispatcher';
// import Observer from './modules/utils/Observer';

let ctrDispatcher = new ControllerDispatcher();
ctrDispatcher.dispatchToController();