/**
 * Initialize the controller dedicated to the current page
 */
export default class ControllerDispatcher {

  importDefaultController() {
    import(/* webpackChunkName: `controller-FallbackController` */ `../controllers/FallbackController`).then((module) =>{
      const DynamicController = module.default; // we except that the component is the the default attribute of the module
      const dynamicController = new DynamicController();
      $(function() {dynamicController.init();});
    }).catch(error => {
      console.error("Failed to load the default controller", error.stack);
    });
  }

  /**
   * This method retrieve the controller name from the html tag and try to istanciate the appropriate Controller
   * If no controller with that name is found, a DefaultController is initialized.
   */
  dispatchToController(){
    let controllerName = $('html').data('controller');
    let controller = `${controllerName}`;

    import(/* webpackChunkName: `controller-[request]` */ `../controllers/${controller}`).then((module) =>{
      const DynamicController = module.default; // we except that the component is the the default attribute of the module
      const dynamicController = new DynamicController();
      $(function() {dynamicController.init();});
    }).catch(error => {
      console.warn("Failed to load the controller", error.stack);
      this.importDefaultController();
    });
  }
}
